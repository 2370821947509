<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				class="container-layout details-document-package"
				:class="{
					'container-layout': $screen.width > 576,
					'container-mobile': $screen.width <= 576
				}"
			>
				<div class="form">
					<b-row>
						<b-col md="12">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ caption1 }}
							</h1>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col class="p-0">
							<b-card no-body>
								<b-row class="back-with-title cursol-pointer py-1">
									<b-col cols="6">
										<button class="btn-transparent" @click="handleClickBack">
											<arrow-left color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
											<span style="display: inline-block; color: #2e495d; font-weight: bolder; font-size: 16px">
												{{ FormMSG(163, 'Back') }}
											</span>
										</button>
									</b-col>
								</b-row>
								<b-card-text>
									<div class="container-field mt-3">
										<b-row class="mb-3">
											<b-col cols="6" v-if="$screen.width >= 992">
												<b-input-group v-if="$screen.width >= 992">
													<b-form-input v-model="filter" type="text" :placeholder="this.FormMSG(11, 'Type to Search')" />
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
															<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="filter = ''" v-else />
														</b-input-group-text>
													</b-input-group-append>
												</b-input-group>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="12" xl="12">
												<!--      for expense and invoices -->
												<CardListBuilder custom-class="hide-on-desktop" :items="expItems" :fields="ticketFields">
													<template slot="actions" slot-scope="data">
														<b-button :variant="data.item.cardIconCheckVariant" size="sm" @click="onCardSelected(data.item)">
															<i class="icon-check"></i>
														</b-button>
														<b-button
															variant="success"
															v-if="data.item.images.length"
															size="sm"
															@click="showPicture(data.item.images, data.item)"
														>
															<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
														</b-button>
													</template>
												</CardListBuilder>
												<b-table
													class="hide-on-tablet"
													:hover="hover"
													responsive="sm"
													ref="tableBudgetCategory"
													selectable
													:selectedVariant="selectedColor"
													:select-mode="selectMode"
													:items="expItems"
													:fields="ticketFields"
													:current-page="currentPage"
													@row-clicked="rowClicked"
													sticky-header="700px"
													:filter="filter"
													:per-page="perPage"
													:head-variant="hv"
													bordered
													small
												>
													<!-- <template slot="pict" slot-scope="data"> -->
													<template v-slot:cell(validatedStatus)="data">
														<div class="wrap-status d-flex">
															<div :class="`status ${classStatus(data.item.validated)}`" style="font-size: 0.7rem">
																{{ data.item.validatedStatus }}
															</div>
														</div>
													</template>
													<template v-slot:cell(pict)="data">
														<b-button
															variant="success"
															v-if="data.item.images.length"
															size="sm"
															@click="showPicture(data.item.images, data.item)"
														>
															<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
														</b-button>
													</template>
												</b-table>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="12" xl="5">
												<b-button
													@click="SendXlsByEmail"
													v-show="showSendXlsByEmail"
													size="sm"
													variant="primary"
													:block="!$screen.md"
													style="margin-right: 10px"
													><!--v-if="this.curExpItem.expenseId"-->
													{{ this.FormMSG(20, 'Send me this Expense by Email') }}
												</b-button>
											</b-col>
										</b-row>
										<b-modal
											header-class="header-class-modal-doc-package"
											:title="FormMSG(21, 'Success!')"
											class="modal-success"
											v-model="successModal"
											@ok="successModal = false"
											ok-variant="success"
											ok-only
										>
											{{ this.FormMSG(22, 'Email sent to your Email address.') }}
										</b-modal>
										<!-- <loading :active.sync="isLoading" :is-full-page="true"></loading> -->
										<b-modal
											header-class="header-class-modal-doc-package"
											:title="FormMSG(24, 'Expense item comment')"
											class="modal-danger"
											v-model="ModalExpenseItemComment"
											@ok="ModalExpenseItemComment = false"
											ok-variant="danger"
											ok-only
										>
											<b-row>
												<b-col cols="12" sm="12">
													<b-form-group :label="FormMSG(25, 'Comment:')" label-for="comment" :label-cols="2">
														<b-form-textarea :disabled="true" id="comment" v-model="expenseItemComment" rows="2"></b-form-textarea>
													</b-form-group>
												</b-col>
											</b-row>
										</b-modal>
									</div>
								</b-card-text>
							</b-card>
						</b-col>
					</b-row>
				</div>
			</div>
		</transition>
		<ExpenseService
			:key="expenseModalRefreshToken"
			v-model="isExpenseModalOpen"
			:expense-type="expenseType"
			:edit-data="currEditExpense"
			@expense-service-main:error="getError"
			@submited="handleModalSubmited"
		/>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { rendCurrency, rendKgCo2 } from '~helpers';
import languageMessages from '@/mixins/languageMessages';
import ExpenseService from '@/components/ExpenseService/Main';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import { isNil } from '~utils';
import { getFileExtension } from '@/shared/helpers';
import { recalculateDistanceValueByUnit } from '@/shared/google/helpers';
import { classStatus } from '@/shared/global-status';
import { Search, X, ArrowLeft } from 'lucide-vue';
import globalMixin from '@/mixins/global.mixin';
import * as _ from 'lodash';

const query_ExpItemsDepCat = gql`
	query ($DepartmentNumber: ID!, $CategoryNumber: ID!) {
		GetExpenseItemsForDepartmentAndCategoryList(DepartmentNumber: $DepartmentNumber, CategoryNumber: $CategoryNumber) {
			id
			comment
			expenseId
			description
			category
			categoryName
			department
			validated
			kgCoTwo
			departmentName
			user {
				name
				firstName
				email
			}
			date
			amountTotal
			paiementType
			paiementTypeName
			category
			supplierId
			supplierName
			images
			type
			amountVat
			km
			fromLocation
			toLocation
			subCategory
		}
	}
`;

export default {
	name: 'ExpenseItemsDepCat',
	mixins: [languageMessages, globalMixin],
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	created() {
		if (this.curDay.date.length === 0) {
			this.firstTimeInitialisation();
		}
		this.reloadData();
	},
	components: {
		Loading,
		ExpenseService,
		Search,
		X,
		ArrowLeft
	},
	data: () => {
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			curBudgetDataDepCat: {},
			curExpItem: {},
			curMonthStr: '',
			expenseItemImagePath: [],
			oneItem: '',
			expItems: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			expenseItemPicture: false,
			openShowRoomPicModal: false,
			ModalExpenseItemComment: false,
			expenseItemComment: '',
			filter: '',
			isLoading: false,
			selectMode: 'single',
			selectedColor: 'primary',
			timeZoneStr: '',
			timeZoneHourDif: 0,
			curDay: {
				id: 0,
				date: '',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				lunch: false,
				hotel: false,
				stageDayUsed: false,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			newCurDay: {
				id: 0,
				date: '2019-09-01',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				lunch: false,
				hotel: false,
				stageDayUsed: false,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			showSendXlsByEmail: false,
			expenseModalRefreshToken: 0,
			isExpenseModalOpen: false,
			expenseType: 0,
			currEditExpense: null
		};
	},
	computed: {
		moneyRequestFields() {
			return [
				{
					key: 'validatedStatus',
					label: this.FormMSG(51, 'Status'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(54, 'Description'),
					sortable: true
				},
				{
					key: 'date',
					label: this.FormMSG(53, 'Date'),
					formatter: (value) => {
						return value.split('T')[0].substring(8) + '/' + value.split('T')[0].substring(5, 7);
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(55, 'Amount'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'paiementTypeName',
					label: this.FormMSG(56, 'Paid'),
					sortable: false
				}
			];
		},
		ticketFields() {
			return [
				{
					key: 'id',
					label: this.FormMSG(52, 'Id'),
					formatter: (value) => {
						return '#' + value;
					},
					sortable: true
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(51, 'Status'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(54, 'Description'),
					sortable: true
				},
				{
					key: 'date',
					label: this.FormMSG(53, 'Date'),
					formatter: (value) => {
						return value.split('T')[0].substring(8) + '/' + value.split('T')[0].substring(5, 7);
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'supplierName',
					label: this.FormMSG(60, 'Supplier'),
					sortable: true
				},
				{
					key: 'fullName',
					label: this.FormMSG(64, 'Name'),
					sortable: true
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(61, 'Amount'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (value) => {
						return rendKgCo2(value);
					},
					class: 'text-right',
					sortable: true
				},
				{
					key: 'paiementTypeName',
					label: this.FormMSG(62, 'Paid'),
					sortable: false
				},
				{
					key: 'pict',
					label: this.FormMSG(63, 'Picture'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		kmFields() {
			return [
				{
					key: 'id',
					label: this.FormMSG(52, 'Id'),
					formatter: (value) => {
						return '#' + value;
					},
					sortable: true
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(51, 'Status'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'date',
					label: this.FormMSG(53, 'Date'),
					formatter: (value) => {
						return value.split('T')[0].substring(8) + '/' + value.split('T')[0].substring(5, 7);
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'fromLocation',
					label: this.FormMSG(65, 'From'),
					sortable: true
				},
				{
					key: 'toLocation',
					label: this.FormMSG(66, 'To'),
					sortable: true
				},
				{
					key: 'km',
					label: this.FormMSG(67, 'Distance'),
					formatter: (value) => {
						return `${recalculateDistanceValueByUnit(value)} ${store.state.myProfile.distanceUnit}`;
					},
					sortable: true
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(61, 'Amount'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				}
			];
		},
		caption1: function () {
			// const cap =
			// 	this.FormMSG(1, "Expenses detail for department") +
			// 	" " +
			// 	(this.curBudgetDataDepCat ? this.curBudgetDataDepCat.departmentName : '') +
			// 	"  " +
			// 	this.FormMSG(2, "and category") +
			// 	"  " +
			// 	(this.curBudgetDataDepCat ? this.curBudgetDataDepCat.budgetDetail.description : '');

			const res = `
				${this.FormMSG(1, 'Expenses detail for department')}: ${this.curBudgetDataDepCat ? this.curBudgetDataDepCat.departmentName : ''} / ${
				this.curBudgetDataDepCat ? this.curBudgetDataDepCat.costCenter + ' - ' + this.curBudgetDataDepCat.budgetDetail.description : ''
			}
			`;
			return res;
		},
		styleObject: function () {
			var myObj = {
				color: 'black',
				fontSize: '16px'
			};
			if (this.curExpItem.validated == 1) {
				myObj.color = 'blue';
			} else if (this.curExpItem.validated == 2) {
				myObj.color = 'yellow';
			} else if (this.curExpItem.validated == 8) {
				myObj.color = 'green';
			} else if (this.curExpItem.validated == 4 || this.curExpItem.validated == 16) {
				myObj.color = 'red';
			}
			return myObj;
		},
		isProd() {
			return store.isProd();
		}
	},
	methods: {
		handleClickBack() {
			if (this.$route.query.budgetPerCategory) {
				this.$router.push({ path: `/budget?activeTabPerCategory=1&search=${this.$route.query.search}` });
			} else {
				this.$router.go(-1);
			}
		},
		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},
		rowClicked(item, index, event) {
			if (store.canEditExpense()) {
				const isCategory = new RegExp('(category)', 'gi').test(this.$router.currentRoute.path);
				this.$nextTick(function () {
					this.showSendXlsByEmail = this.$refs.tableBudgetCategory.isRowSelected(index);
				});
				this.curExpItem = item;
				// console.log("row item :", this.curExpItem);
				if (item.comment.length > 0) {
					this.expenseItemComment = item.comment;
					this.ModalExpenseItemComment = true;
				}
				if (this.isProd && isCategory) {
					this.expenseType = item.type;
					this.currEditExpense = item;
					this.isExpenseModalOpen = true;
				}
			}
		},
		clearCardSelected() {
			const cloneExpItems = this.expItems;
			cloneExpItems.forEach((expItem, index) => {
				expItem.cardIconCheckVariant = 'outline-primary';
				expItem.isCardChecked = false;
				this.$set(this.expItems, index, expItem);
			});
		},
		onCardSelected(item) {
			//clear before apply change
			if (this.curExpItem.id !== item.id) {
				this.clearCardSelected();
			}
			// change
			item.isCardChecked = !item.isCardChecked;
			if (item.isCardChecked) {
				item.cardIconCheckVariant = 'warning';
			} else {
				item.cardIconCheckVariant = 'outline-primary';
			}
			this.$set(
				this.expItems,
				this.expItems.findIndex((expItem) => expItem.id === item.id),
				item
			);
			this.showSendXlsByEmail = item.isCardChecked;

			this.curExpItem = item;
			// console.log("row item :", this.curExpItem);
			if (item.comment.length > 0) {
				this.expenseItemComment = item.comment;
				this.ModalExpenseItemComment = true;
			}
		},
		getCurrentDate() {
			var currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		firstTimeInitialisation() {
			this.curExpItem.expenseId = 0;
			this.setTimeZoneStr();
			this.curBudgetDataDepCat = store.getCurBudgetDataForDepartmentCategory();
			//console.log("firstInit CurDepCat:", this.curBudgetDataDepCat)
		},
		updateTimeToTZ(timeString) {
			this.setTimeZoneStr();
			if (timeString.length == 0) {
				return timeString;
			}
			// check if already converted
			//  console.log("in updateTimeToTZ  RECEIVING:",timeString );
			//console.log("substring 9=",timeString.substring(9) );
			//console.log("in updateTimeToTZ timeZoneStr=",this.timeZoneStr);

			if (timeString.substring(9) != this.timeZoneStr) {
				// needs conversion
				//console.log("in updateTimeToTZ inval=",timeString)
				// extract time value
				var timStr = timeString.substring(1, 3);
				var hGmt = parseInt(timStr, 10);
				var h = (hGmt + this.timeZoneHourDif) % 24;
				if (h > 9) {
					var retval = 'T' + h + timeString.substring(3);
				} else {
					var retval = 'T0' + h + timeString.substring(3);
				}
				//    console.log("in updateTimeToTZ retval=",retval);
				retval = retval.substring(0, 9) + this.timeZoneStr;
				//    console.log("in updateTimeToTZ retval new TZ=",retval);
				return retval;
			} else {
				// does not need conversion
				var retval = timeString;
				//    console.log("in updateTimeToTZ retval kept existing conversion=",retval);
				return retval;
			}
		},
		updateTZdataEach(expItemData) {
			// setting row color
			expItemData.validatedStatus = this.validatedText(expItemData.validated);
			expItemData.fullName = expItemData.user.name + ' ' + expItemData.user.firstName;
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			//expItemData._rowVariant = this.validatedColor(expItemData.validated);
			//expItemData.date = expItemData.date.substring(0, 10) + this.updateTimeToTZ(expItemData.date.substring(10));
		},
		setTimeZoneStr() {
			// getting timezone
			if (this.timeZoneStr.length > 0) {
				return;
			}
			var d = new Date();
			var n = d.getTimezoneOffset();
			if (n < 0) {
				var hOffset = -n / 60;
				var sign = '+';
			} else {
				var hOffset = n / 60;
				var sign = '-';
			}
			var BASE = '00';
			this.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.timeZoneStr = this.timeZoneStr + ':00';
			this.timeZoneHourDif = hOffset;
		},
		validatedText(validated) {
			// returns the label text corresponding to the validated value
			return getTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'grey';
			}
			return retval;
		},
		reloadData() {
			this.isLoading = true;
			//  this.curBudgetDataDepCat = store.getCurBudgetDataForDepartmentCategory();
			// console.log('this.curBudgetDataDepCat: ', this.$route.params);
			const { department, category } = this.$route.params;
			const DepartmentNumber = parseInt(department, 10); // this.curBudgetDataDepCat.department;
			const CategoryNumber = parseInt(category, 10); // this.curBudgetDataDepCat.category;
			//console.log("before call:",CategoryNumber)
			this.$apollo
				.query({
					query: query_ExpItemsDepCat,
					variables: {
						DepartmentNumber,
						CategoryNumber
					},
					fetchPolicy: 'no-cache'
					// options: { fetchPolicy: 'network-only' },
					// options: { fetchPolicy: 'no-cache' },
					// force update from server
				})
				.then((result) => {
					result.data.GetExpenseItemsForDepartmentAndCategoryList.forEach(this.updateTZdataEach);
					this.expItems = result.data.GetExpenseItemsForDepartmentAndCategoryList;
					//  console.log("expense items:", this.expItems);
					this.expItems.map((expItem) => {
						expItem.cardIconCheckVariant = 'outline-primary';
						expItem.isCardChecked = false;

						return expItem;
					});
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				})
				.finally(() => (this.isLoading = false));
		},
		SendXlsByEmail() {
			this.isLoading = true;
			this.warning = '';
			if (this.curExpItem.expenseId == 0) {
				return;
			}
			var idToPrint = this.curExpItem.expenseId;
			var fullPath = process.env.VUE_APP_GQL + '/expenseReport/' + idToPrint;
			// console.log("fullpath", fullPath);
			// console.log("Bearer + store.state.token:", "Bearer " + store.state.token);
			var config = {
				headers: {
					Authorization: 'Bearer ' + store.state.token
				}
			};
			axios
				.get(fullPath, config)
				.then((response) => {
					// console.log({
					// 	response,
					// });
					if (response.status == 200) {
						this.successModal = true;
					}
				})
				.catch((error) => {
					console.log({
						error
					});
					if (error.message.includes('Network')) {
						this.warning = 'Network error.';
					} else if (error.response) {
						if (error.response.status == 401) {
							this.warning = 'Not connected.';
						} else if (error.response.status == 400) {
							this.warning = 'Invalid format.';
						} else if (error.response.status == 404) {
							this.warning = 'No Expense.';
						}
					}
				})
				.finally(() => (this.isLoading = false));
		},
		handleModalSubmited() {
			this.expenseModalRefreshToken = this.expenseModalRefreshToken + 1;
			this.$toast({
				message: this.FormMSG(4, 'Your expense was saved successfully.'),
				title: this.FormMSG(162, 'Success'),
				variant: 'success'
			});
			this.isExpenseModalOpen = false;
			this.reloadData();
		},
		classStatus(validated) {
			return classStatus(validated);
		},
		getError(payload) {
			if (!_.isNil(payload) && !_.isNil(payload.status) && payload.status === 509) {
				this.isNewExpenseModalOpen = true;
			}
		}
	}
};
</script>
